.OverlayedImage
{
    position: relative;
}
.OverlayedImage > div
{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.OverlayedImage:hover > div
{
    opacity: 1;
}
