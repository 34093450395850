.Search
{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
}

.Search-Input
{
    width: 80%;
    font-size: 18px;
    border-radius: 5px;
    border-width: 2px;
    border-color: #444;
    border-style: solid;
    padding: 2px 5px;
    background-color: white;
    box-sizing: content-box;
    margin-bottom: 20px;
}
