.MemePage-Loading
{
    height: 200px;

}

.MemePage-Title
{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.MemePage-Meme
{
    width: 100%;
    height: auto;
}

.MemePage-Fade-enter
{
    opacity: 0;
}
.MemePage-Fade-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}
.MemePage-Fade-exit {
  opacity: 1;
}
.MemePage-Fade-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

