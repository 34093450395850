.App {
    margin: 0;
    display: flex;
    justify-content: center;
    font-family: sans-serif;
}

.App-Content
{
    width: min(100vw, 768px);
}

.App-Nav
{
    display: flex;
    margin-bottom: 20px;
}
.App-Nav > div
{
    flex-grow: 1;
    flex-basis: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.App-Nav a
{
    text-decoration: none;
    color: inherit;
    transition: background-color linear 200ms, color linear 200ms;
}
.App-Nav a:hover
{
    background-color: #aaa;
}
.App-Nav-Link
{
    border: 1px solid black;
    border-radius: 2px;
    padding: 5px;
    font-size: 12px;
}

.App-Title
{
    text-align: center;
}

.App-Page
{
    position: relative;
}
.App-Page > div
{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%
}



.App-Loader,
.App-Loader:before,
.App-Loader:after {
    background: #000000;
    -webkit-animation: App-Loader-Animation 1s infinite ease-in-out;
    animation: App-Loader-Animation 1s infinite ease-in-out;
    width: 1em;
    height: 4em;
}
.App-Loader {
    color: #000000;
    text-indent: -9999em;
    margin: 88px auto;
    position: relative;
    font-size: 11px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}
.App-Loader:before,
.App-Loader:after {
    position: absolute;
    top: 0;
    content: '';
}
.App-Loader:before {
    left: -1.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}
.App-Loader:after {
    left: 1.5em;
}
@-webkit-keyframes App-Loader-Animation {
    0%,
    80%,
    100% {
        box-shadow: 0 0;
        height: 4em;
    }
    40% {
        box-shadow: 0 -2em;
        height: 5em;
    }
}
@keyframes App-Loader-Animation {
    0%,
    80%,
    100% {
        box-shadow: 0 0;
        height: 4em;
    }
    40% {
        box-shadow: 0 -2em;
        height: 5em;
    }
}


