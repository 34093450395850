@media only screen and (min-width: 768px)
{
    .Images
    {
        display: flex;
        width: 100%;
    }
    .Images > div
    {
        flex-grow: 1;
        flex-basis: 0;
    }
}
