.ImageColumn
{
    font-size: 0;
    margin: 0 1px;
}

.ImageColumn-Overlay
{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 40px;
    background-color: #0a0a0a;
    color: white;
    z-index: 99;
    padding: 0 8px;
    box-sizing: border-box;
    font-size: 14px;
}
.ImageColumn-Overlay > *
{
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
.ImageColumn-Overlay > a
{
    margin-left: 10px;
    right: 8px;
    color: white;
}
